.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  overflow: scroll;
}

.modal-content {
  background: #fff;
  border-radius: 2rem;
  width: 40rem;
  max-width: 100%;
  max-height: 100%;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  margin: .5rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 3rem;
  word-wrap: normal;
}

.text {
  font-family: 'Syne Mono',
  monospace;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}